.SwapCompleted {
  font-family: "Montserrat", sans-serif; }
  .SwapCompleted_top {
    width: 100%; }
    .SwapCompleted_top h4 {
      text-align: center;
      color: #3D4767;
      font-weight: 700;
      font-size: 14px; }
    .SwapCompleted_top h1 {
      text-align: center;
      font-size: 36px;
      color: #1D1E21; }
    .SwapCompleted_top h5 {
      color: #747E8D;
      text-align: center; }
  .SwapCompleted_transactionTitle {
    font-weight: bold;
    color: #1D1E21; }
    .SwapCompleted_transactionTitle::hover {
      cursor: pointer;
      text-decoration: underline; }
  .SwapCompleted_link {
    padding-left: 40px;
    z-index: 3; }
    .SwapCompleted_link:hover {
      cursor: pointer; }
  .SwapCompleted_receivedAmount {
    font-size: 30px;
    font-weight: 300; }
  .SwapCompleted_sentAmount {
    font-size: 28px;
    text-align: center;
    font-weight: 300; }
  .SwapCompleted_rateAmount {
    font-weight: 600;
    font-size: 24px;
    text-align: center; }
  .SwapCompleted_confs {
    color: #747E8D;
    padding-left: 5px; }
  .SwapCompleted_confText {
    font-size: 12px;
    padding-left: 2px; }
  .SwapCompleted_bottom {
    z-index: 3;
    display: flex;
    flex-flow: row-reverse nowrap;
    justify-content: space-between; }
    .SwapCompleted_bottom h4 {
      font-size: 14px; }
  .SwapCompleted_bottomButton {
    display: flex;
    justify-content: center; }
  .SwapCompleted_shareSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center; }
    .SwapCompleted_shareSection h3 {
      font-size: 14px;
      font-weight: bold; }
    .SwapCompleted_shareSection img:hover {
      cursor: pointer; }
  .SwapCompleted_right {
    padding-right: 10px;
    width: 100%; }
  .SwapCompleted_left {
    padding-left: 30px;
    width: 100%; }
  .SwapCompleted_transactionHash {
    color: #9D4DFA;
    width: 100%;
    padding-left: 4px; }
  .SwapCompleted_shareLeft {
    display: flex;
    flex-flow: row nowrap; }
  .SwapCompleted_shareRight {
    display: flex;
    flex-flow: row nowrap; }
  .SwapCompleted_confetti-wrapper {
    height: 500px;
    position: absolute;
    width: 100%;
    z-index: 1; }
  .SwapCompleted [class|="confetti"] {
    position: absolute; }
  .SwapCompleted .confetti-0 {
    width: 4px;
    height: 1.6px;
    background-color: #1CE5C3;
    top: 690px;
    left: 1%;
    opacity: 0.51596;
    transform: rotate(90.20137deg);
    animation: drop-0 4.69458s 0.54751s infinite; }

@keyframes drop-0 {
  0% {
    top: 80px;
    left: 3%; } }
  .SwapCompleted .confetti-1 {
    width: 2px;
    height: 0.8px;
    background-color: #1CE5C3;
    top: 690px;
    left: 36%;
    opacity: 1.11937;
    transform: rotate(232.23009deg);
    animation: drop-1 4.1631s 0.22071s infinite; }

@keyframes drop-1 {
  0% {
    top: 80px;
    left: 37%; } }
  .SwapCompleted .confetti-2 {
    width: 1px;
    height: 0.4px;
    background-color: #1CE5C3;
    top: 690px;
    left: 30%;
    opacity: 0.90133;
    transform: rotate(141.87344deg);
    animation: drop-2 4.25925s 0.64584s infinite; }

@keyframes drop-2 {
  0% {
    top: 80px;
    left: 34%; } }
  .SwapCompleted .confetti-3 {
    width: 7px;
    height: 2.8px;
    background-color: #1CE5C3;
    top: 690px;
    left: 24%;
    opacity: 0.87498;
    transform: rotate(214.79358deg);
    animation: drop-3 4.03067s 0.36009s infinite; }

@keyframes drop-3 {
  0% {
    top: 80px;
    left: 26%; } }
  .SwapCompleted .confetti-4 {
    width: 4px;
    height: 1.6px;
    background-color: #9D4DFA;
    top: 690px;
    left: 72%;
    opacity: 0.54908;
    transform: rotate(104.28752deg);
    animation: drop-4 4.19562s 0.21983s infinite; }

@keyframes drop-4 {
  0% {
    top: 80px;
    left: 74%; } }
  .SwapCompleted .confetti-5 {
    width: 7px;
    height: 2.8px;
    background-color: #9D4DFA;
    top: 690px;
    left: 73%;
    opacity: 0.5218;
    transform: rotate(1.26857deg);
    animation: drop-5 4.1265s 0.71471s infinite; }

@keyframes drop-5 {
  0% {
    top: 80px;
    left: 74%; } }
  .SwapCompleted .confetti-6 {
    width: 7px;
    height: 2.8px;
    background-color: #9D4DFA;
    top: 690px;
    left: 31%;
    opacity: 0.35633;
    transform: rotate(357.54866deg);
    animation: drop-6 4.22776s 0.31754s infinite; }

@keyframes drop-6 {
  0% {
    top: 80px;
    left: 32%; } }
  .SwapCompleted .confetti-7 {
    width: 3px;
    height: 1.2px;
    background-color: #1CE5C3;
    top: 690px;
    left: 35%;
    opacity: 0.83986;
    transform: rotate(239.05023deg);
    animation: drop-7 4.322s 0.37827s infinite; }

@keyframes drop-7 {
  0% {
    top: 80px;
    left: 39%; } }
  .SwapCompleted .confetti-8 {
    width: 4px;
    height: 1.6px;
    background-color: #1CE5C3;
    top: 690px;
    left: 33%;
    opacity: 1.18933;
    transform: rotate(343.59116deg);
    animation: drop-8 4.45384s 0.62813s infinite; }

@keyframes drop-8 {
  0% {
    top: 80px;
    left: 36%; } }
  .SwapCompleted .confetti-9 {
    width: 1px;
    height: 0.4px;
    background-color: #9D4DFA;
    top: 690px;
    left: 35%;
    opacity: 0.43553;
    transform: rotate(277.75513deg);
    animation: drop-9 4.35891s 0.70005s infinite; }

@keyframes drop-9 {
  0% {
    top: 80px;
    left: 40%; } }
  .SwapCompleted .confetti-10 {
    width: 3px;
    height: 1.2px;
    background-color: #1CE5C3;
    top: 690px;
    left: 10%;
    opacity: 1.19787;
    transform: rotate(219.736deg);
    animation: drop-10 4.01701s 0.78802s infinite; }

@keyframes drop-10 {
  0% {
    top: 80px;
    left: 13%; } }
  .SwapCompleted .confetti-11 {
    width: 8px;
    height: 3.2px;
    background-color: #9D4DFA;
    top: 690px;
    left: 77%;
    opacity: 1.22616;
    transform: rotate(50.49265deg);
    animation: drop-11 4.34369s 0.08922s infinite; }

@keyframes drop-11 {
  0% {
    top: 80px;
    left: 80%; } }
  .SwapCompleted .confetti-12 {
    width: 3px;
    height: 1.2px;
    background-color: #F12274;
    top: 690px;
    left: 17%;
    opacity: 0.75202;
    transform: rotate(61.01713deg);
    animation: drop-12 4.70006s 0.47355s infinite; }

@keyframes drop-12 {
  0% {
    top: 80px;
    left: 21%; } }
  .SwapCompleted .confetti-13 {
    width: 3px;
    height: 1.2px;
    background-color: #1CE5C3;
    top: 690px;
    left: 56%;
    opacity: 0.92313;
    transform: rotate(282.66172deg);
    animation: drop-13 4.26374s 0.16799s infinite; }

@keyframes drop-13 {
  0% {
    top: 80px;
    left: 57%; } }
  .SwapCompleted .confetti-14 {
    width: 2px;
    height: 0.8px;
    background-color: #9D4DFA;
    top: 690px;
    left: 19%;
    opacity: 0.58038;
    transform: rotate(232.17971deg);
    animation: drop-14 4.6944s 0.77742s infinite; }

@keyframes drop-14 {
  0% {
    top: 80px;
    left: 23%; } }
  .SwapCompleted .confetti-15 {
    width: 5px;
    height: 2px;
    background-color: #1CE5C3;
    top: 690px;
    left: 33%;
    opacity: 0.822;
    transform: rotate(2.64476deg);
    animation: drop-15 4.30774s 0.09302s infinite; }

@keyframes drop-15 {
  0% {
    top: 80px;
    left: 34%; } }
  .SwapCompleted .confetti-16 {
    width: 8px;
    height: 3.2px;
    background-color: #F12274;
    top: 690px;
    left: 83%;
    opacity: 1.14717;
    transform: rotate(344.70274deg);
    animation: drop-16 4.43648s 0.43767s infinite; }

@keyframes drop-16 {
  0% {
    top: 80px;
    left: 87%; } }
  .SwapCompleted .confetti-17 {
    width: 2px;
    height: 0.8px;
    background-color: #1CE5C3;
    top: 690px;
    left: 27%;
    opacity: 1.07045;
    transform: rotate(329.62218deg);
    animation: drop-17 4.40095s 0.82592s infinite; }

@keyframes drop-17 {
  0% {
    top: 80px;
    left: 29%; } }
  .SwapCompleted .confetti-18 {
    width: 3px;
    height: 1.2px;
    background-color: #9D4DFA;
    top: 690px;
    left: 76%;
    opacity: 0.97219;
    transform: rotate(171.82579deg);
    animation: drop-18 4.65846s 0.58726s infinite; }

@keyframes drop-18 {
  0% {
    top: 80px;
    left: 77%; } }
  .SwapCompleted .confetti-19 {
    width: 1px;
    height: 0.4px;
    background-color: #1CE5C3;
    top: 690px;
    left: 67%;
    opacity: 0.77851;
    transform: rotate(52.59341deg);
    animation: drop-19 4.70035s 0.65829s infinite; }

@keyframes drop-19 {
  0% {
    top: 80px;
    left: 68%; } }
  .SwapCompleted .confetti-20 {
    width: 5px;
    height: 2px;
    background-color: #9D4DFA;
    top: 690px;
    left: 58%;
    opacity: 0.45994;
    transform: rotate(307.56387deg);
    animation: drop-20 4.26916s 0.05181s infinite; }

@keyframes drop-20 {
  0% {
    top: 80px;
    left: 59%; } }
  .SwapCompleted .confetti-21 {
    width: 3px;
    height: 1.2px;
    background-color: #1CE5C3;
    top: 690px;
    left: 68%;
    opacity: 0.50544;
    transform: rotate(290.76139deg);
    animation: drop-21 4.36624s 0.64347s infinite; }

@keyframes drop-21 {
  0% {
    top: 80px;
    left: 69%; } }
  .SwapCompleted .confetti-22 {
    width: 7px;
    height: 2.8px;
    background-color: #1CE5C3;
    top: 690px;
    left: 9%;
    opacity: 1.04838;
    transform: rotate(23.97403deg);
    animation: drop-22 4.14483s 0.51814s infinite; }

@keyframes drop-22 {
  0% {
    top: 80px;
    left: 10%; } }
  .SwapCompleted .confetti-23 {
    width: 7px;
    height: 2.8px;
    background-color: #9D4DFA;
    top: 690px;
    left: 88%;
    opacity: 0.75788;
    transform: rotate(263.754deg);
    animation: drop-23 4.36607s 0.12758s infinite; }

@keyframes drop-23 {
  0% {
    top: 80px;
    left: 89%; } }
  .SwapCompleted .confetti-24 {
    width: 8px;
    height: 3.2px;
    background-color: #1CE5C3;
    top: 690px;
    left: 61%;
    opacity: 0.80192;
    transform: rotate(202.89518deg);
    animation: drop-24 4.95261s 0.4558s infinite; }

@keyframes drop-24 {
  0% {
    top: 80px;
    left: 66%; } }
  .SwapCompleted .confetti-25 {
    width: 2px;
    height: 0.8px;
    background-color: #1CE5C3;
    top: 690px;
    left: 12%;
    opacity: 0.98976;
    transform: rotate(39.42133deg);
    animation: drop-25 4.47545s 0.73015s infinite; }

@keyframes drop-25 {
  0% {
    top: 80px;
    left: 13%; } }
  .SwapCompleted .confetti-26 {
    width: 4px;
    height: 1.6px;
    background-color: #F12274;
    top: 690px;
    left: 55%;
    opacity: 1.21431;
    transform: rotate(116.72817deg);
    animation: drop-26 4.55663s 0.40852s infinite; }

@keyframes drop-26 {
  0% {
    top: 80px;
    left: 56%; } }
  .SwapCompleted .confetti-27 {
    width: 4px;
    height: 1.6px;
    background-color: #F12274;
    top: 690px;
    left: 62%;
    opacity: 0.93795;
    transform: rotate(122.77198deg);
    animation: drop-27 4.02733s 0.24061s infinite; }

@keyframes drop-27 {
  0% {
    top: 80px;
    left: 67%; } }
  .SwapCompleted .confetti-28 {
    width: 1px;
    height: 0.4px;
    background-color: #F12274;
    top: 690px;
    left: 85%;
    opacity: 0.68508;
    transform: rotate(322.77426deg);
    animation: drop-28 4.11949s 0.84323s infinite; }

@keyframes drop-28 {
  0% {
    top: 80px;
    left: 87%; } }
  .SwapCompleted .confetti-29 {
    width: 1px;
    height: 0.4px;
    background-color: #1CE5C3;
    top: 690px;
    left: 64%;
    opacity: 1.22217;
    transform: rotate(329.6638deg);
    animation: drop-29 4.10222s 0.09837s infinite; }

@keyframes drop-29 {
  0% {
    top: 80px;
    left: 69%; } }
  .SwapCompleted .confetti-30 {
    width: 2px;
    height: 0.8px;
    background-color: #1CE5C3;
    top: 690px;
    left: 76%;
    opacity: 1.09927;
    transform: rotate(107.66662deg);
    animation: drop-30 4.27918s 0.39905s infinite; }

@keyframes drop-30 {
  0% {
    top: 80px;
    left: 80%; } }
  .SwapCompleted .confetti-31 {
    width: 7px;
    height: 2.8px;
    background-color: #F12274;
    top: 690px;
    left: 43%;
    opacity: 0.84747;
    transform: rotate(53.32074deg);
    animation: drop-31 4.37575s 0.9447s infinite; }

@keyframes drop-31 {
  0% {
    top: 80px;
    left: 46%; } }
  .SwapCompleted .confetti-32 {
    width: 8px;
    height: 3.2px;
    background-color: #9D4DFA;
    top: 690px;
    left: 91%;
    opacity: 0.62072;
    transform: rotate(106.40895deg);
    animation: drop-32 4.58871s 0.02162s infinite; }

@keyframes drop-32 {
  0% {
    top: 80px;
    left: 94%; } }
  .SwapCompleted .confetti-33 {
    width: 1px;
    height: 0.4px;
    background-color: #9D4DFA;
    top: 690px;
    left: 71%;
    opacity: 0.82653;
    transform: rotate(210.91987deg);
    animation: drop-33 4.82986s 0.64523s infinite; }

@keyframes drop-33 {
  0% {
    top: 80px;
    left: 76%; } }
  .SwapCompleted .confetti-34 {
    width: 5px;
    height: 2px;
    background-color: #9D4DFA;
    top: 690px;
    left: 84%;
    opacity: 0.52699;
    transform: rotate(140.08335deg);
    animation: drop-34 4.64802s 0.22746s infinite; }

@keyframes drop-34 {
  0% {
    top: 80px;
    left: 87%; } }
  .SwapCompleted .confetti-35 {
    width: 6px;
    height: 2.4px;
    background-color: #1CE5C3;
    top: 690px;
    left: 68%;
    opacity: 0.54354;
    transform: rotate(285.27112deg);
    animation: drop-35 4.11435s 0.47046s infinite; }

@keyframes drop-35 {
  0% {
    top: 80px;
    left: 71%; } }
  .SwapCompleted .confetti-36 {
    width: 8px;
    height: 3.2px;
    background-color: #1CE5C3;
    top: 690px;
    left: 63%;
    opacity: 1.04917;
    transform: rotate(120.5829deg);
    animation: drop-36 4.85514s 0.08369s infinite; }

@keyframes drop-36 {
  0% {
    top: 80px;
    left: 64%; } }
  .SwapCompleted .confetti-37 {
    width: 3px;
    height: 1.2px;
    background-color: #F12274;
    top: 690px;
    left: 76%;
    opacity: 1.14483;
    transform: rotate(227.59171deg);
    animation: drop-37 4.93243s 0.39185s infinite; }

@keyframes drop-37 {
  0% {
    top: 80px;
    left: 78%; } }
  .SwapCompleted .confetti-38 {
    width: 7px;
    height: 2.8px;
    background-color: #9D4DFA;
    top: 690px;
    left: 59%;
    opacity: 0.45659;
    transform: rotate(299.62897deg);
    animation: drop-38 4.56735s 0.87491s infinite; }

@keyframes drop-38 {
  0% {
    top: 80px;
    left: 63%; } }
  .SwapCompleted .confetti-39 {
    width: 5px;
    height: 2px;
    background-color: #1CE5C3;
    top: 690px;
    left: 76%;
    opacity: 0.60771;
    transform: rotate(124.634deg);
    animation: drop-39 4.53221s 0.86328s infinite; }

@keyframes drop-39 {
  0% {
    top: 80px;
    left: 77%; } }
  .SwapCompleted .confetti-40 {
    width: 5px;
    height: 2px;
    background-color: #1CE5C3;
    top: 690px;
    left: 68%;
    opacity: 0.59676;
    transform: rotate(219.63505deg);
    animation: drop-40 4.8111s 0.58765s infinite; }

@keyframes drop-40 {
  0% {
    top: 80px;
    left: 69%; } }
  .SwapCompleted .confetti-41 {
    width: 1px;
    height: 0.4px;
    background-color: #1CE5C3;
    top: 690px;
    left: 28%;
    opacity: 0.91953;
    transform: rotate(241.84465deg);
    animation: drop-41 4.32511s 0.44775s infinite; }

@keyframes drop-41 {
  0% {
    top: 80px;
    left: 32%; } }
  .SwapCompleted .confetti-42 {
    width: 4px;
    height: 1.6px;
    background-color: #9D4DFA;
    top: 690px;
    left: 37%;
    opacity: 0.87245;
    transform: rotate(217.33331deg);
    animation: drop-42 4.29832s 0.3041s infinite; }

@keyframes drop-42 {
  0% {
    top: 80px;
    left: 40%; } }
  .SwapCompleted .confetti-43 {
    width: 4px;
    height: 1.6px;
    background-color: #F12274;
    top: 690px;
    left: 79%;
    opacity: 1.03336;
    transform: rotate(306.85088deg);
    animation: drop-43 4.98565s 0.98087s infinite; }

@keyframes drop-43 {
  0% {
    top: 80px;
    left: 82%; } }
  .SwapCompleted .confetti-44 {
    width: 8px;
    height: 3.2px;
    background-color: #9D4DFA;
    top: 690px;
    left: 95%;
    opacity: 0.54777;
    transform: rotate(290.79768deg);
    animation: drop-44 4.09259s 0.10695s infinite; }

@keyframes drop-44 {
  0% {
    top: 80px;
    left: 100%; } }
  .SwapCompleted .confetti-45 {
    width: 7px;
    height: 2.8px;
    background-color: #F12274;
    top: 690px;
    left: 85%;
    opacity: 1.13889;
    transform: rotate(264.75329deg);
    animation: drop-45 4.60657s 0.69114s infinite; }

@keyframes drop-45 {
  0% {
    top: 80px;
    left: 88%; } }
  .SwapCompleted .confetti-46 {
    width: 2px;
    height: 0.8px;
    background-color: #1CE5C3;
    top: 690px;
    left: 45%;
    opacity: 1.06436;
    transform: rotate(81.23505deg);
    animation: drop-46 4.98311s 0.56702s infinite; }

@keyframes drop-46 {
  0% {
    top: 80px;
    left: 46%; } }
  .SwapCompleted .confetti-47 {
    width: 3px;
    height: 1.2px;
    background-color: #1CE5C3;
    top: 690px;
    left: 62%;
    opacity: 0.94622;
    transform: rotate(46.86243deg);
    animation: drop-47 4.39591s 0.64311s infinite; }

@keyframes drop-47 {
  0% {
    top: 80px;
    left: 66%; } }
  .SwapCompleted .confetti-48 {
    width: 7px;
    height: 2.8px;
    background-color: #9D4DFA;
    top: 690px;
    left: 9%;
    opacity: 0.92242;
    transform: rotate(129.96652deg);
    animation: drop-48 4.35026s 0.20863s infinite; }

@keyframes drop-48 {
  0% {
    top: 80px;
    left: 11%; } }
  .SwapCompleted .confetti-49 {
    width: 7px;
    height: 2.8px;
    background-color: #1CE5C3;
    top: 690px;
    left: 64%;
    opacity: 0.65284;
    transform: rotate(231.51093deg);
    animation: drop-49 4.48582s 0.3455s infinite; }

@keyframes drop-49 {
  0% {
    top: 80px;
    left: 69%; } }
  .SwapCompleted .confetti-50 {
    width: 2px;
    height: 0.8px;
    background-color: #9D4DFA;
    top: 690px;
    left: 89%;
    opacity: 0.30808;
    transform: rotate(105.98109deg);
    animation: drop-50 4.11691s 0.13776s infinite; }

@keyframes drop-50 {
  0% {
    top: 80px;
    left: 93%; } }
  .SwapCompleted .confetti-51 {
    width: 4px;
    height: 1.6px;
    background-color: #1CE5C3;
    top: 690px;
    left: 34%;
    opacity: 0.79363;
    transform: rotate(17.64855deg);
    animation: drop-51 4.89591s 0.94885s infinite; }

@keyframes drop-51 {
  0% {
    top: 80px;
    left: 38%; } }
  .SwapCompleted .confetti-52 {
    width: 4px;
    height: 1.6px;
    background-color: #1CE5C3;
    top: 690px;
    left: 17%;
    opacity: 1.20268;
    transform: rotate(84.05784deg);
    animation: drop-52 4.03287s 0.53406s infinite; }

@keyframes drop-52 {
  0% {
    top: 80px;
    left: 22%; } }
  .SwapCompleted .confetti-53 {
    width: 6px;
    height: 2.4px;
    background-color: #1CE5C3;
    top: 690px;
    left: 66%;
    opacity: 0.36159;
    transform: rotate(336.85945deg);
    animation: drop-53 4.90205s 0.46929s infinite; }

@keyframes drop-53 {
  0% {
    top: 80px;
    left: 69%; } }
  .SwapCompleted .confetti-54 {
    width: 8px;
    height: 3.2px;
    background-color: #9D4DFA;
    top: 690px;
    left: 2%;
    opacity: 1.15963;
    transform: rotate(100.66506deg);
    animation: drop-54 4.21615s 0.01887s infinite; }

@keyframes drop-54 {
  0% {
    top: 80px;
    left: 7%; } }
  .SwapCompleted .confetti-55 {
    width: 6px;
    height: 2.4px;
    background-color: #F12274;
    top: 690px;
    left: 59%;
    opacity: 0.49905;
    transform: rotate(63.4677deg);
    animation: drop-55 4.81332s 0.90404s infinite; }

@keyframes drop-55 {
  0% {
    top: 80px;
    left: 61%; } }
  .SwapCompleted .confetti-56 {
    width: 6px;
    height: 2.4px;
    background-color: #1CE5C3;
    top: 690px;
    left: 61%;
    opacity: 0.56609;
    transform: rotate(253.01518deg);
    animation: drop-56 4.87541s 0.30926s infinite; }

@keyframes drop-56 {
  0% {
    top: 80px;
    left: 64%; } }
  .SwapCompleted .confetti-57 {
    width: 7px;
    height: 2.8px;
    background-color: #1CE5C3;
    top: 690px;
    left: 91%;
    opacity: 1.29514;
    transform: rotate(242.50166deg);
    animation: drop-57 4.17973s 0.47282s infinite; }

@keyframes drop-57 {
  0% {
    top: 80px;
    left: 93%; } }
  .SwapCompleted .confetti-58 {
    width: 1px;
    height: 0.4px;
    background-color: #9D4DFA;
    top: 690px;
    left: 41%;
    opacity: 1.22945;
    transform: rotate(205.12697deg);
    animation: drop-58 4.16438s 0.23239s infinite; }

@keyframes drop-58 {
  0% {
    top: 80px;
    left: 43%; } }
  .SwapCompleted .confetti-59 {
    width: 3px;
    height: 1.2px;
    background-color: #1CE5C3;
    top: 690px;
    left: 94%;
    opacity: 0.60903;
    transform: rotate(50.67047deg);
    animation: drop-59 4.27209s 0.55216s infinite; }

@keyframes drop-59 {
  0% {
    top: 80px;
    left: 99%; } }
  .SwapCompleted .confetti-60 {
    width: 5px;
    height: 2px;
    background-color: #1CE5C3;
    top: 690px;
    left: 35%;
    opacity: 1.27829;
    transform: rotate(137.92629deg);
    animation: drop-60 4.03084s 0.17623s infinite; }

@keyframes drop-60 {
  0% {
    top: 80px;
    left: 38%; } }
  .SwapCompleted .confetti-61 {
    width: 1px;
    height: 0.4px;
    background-color: #F12274;
    top: 690px;
    left: 23%;
    opacity: 1.16658;
    transform: rotate(127.29458deg);
    animation: drop-61 4.44767s 0.56428s infinite; }

@keyframes drop-61 {
  0% {
    top: 80px;
    left: 24%; } }
  .SwapCompleted .confetti-62 {
    width: 3px;
    height: 1.2px;
    background-color: #F12274;
    top: 690px;
    left: 93%;
    opacity: 0.65897;
    transform: rotate(63.78341deg);
    animation: drop-62 4.15951s 0.64029s infinite; }

@keyframes drop-62 {
  0% {
    top: 80px;
    left: 97%; } }
  .SwapCompleted .confetti-63 {
    width: 2px;
    height: 0.8px;
    background-color: #F12274;
    top: 690px;
    left: 22%;
    opacity: 0.82994;
    transform: rotate(23.26846deg);
    animation: drop-63 4.23869s 0.56705s infinite; }

@keyframes drop-63 {
  0% {
    top: 80px;
    left: 24%; } }
  .SwapCompleted .confetti-64 {
    width: 5px;
    height: 2px;
    background-color: #1CE5C3;
    top: 690px;
    left: 55%;
    opacity: 0.92027;
    transform: rotate(197.98191deg);
    animation: drop-64 4.11343s 0.43896s infinite; }

@keyframes drop-64 {
  0% {
    top: 80px;
    left: 58%; } }
  .SwapCompleted .confetti-65 {
    width: 8px;
    height: 3.2px;
    background-color: #1CE5C3;
    top: 690px;
    left: 55%;
    opacity: 0.50879;
    transform: rotate(290.45497deg);
    animation: drop-65 4.58484s 0.18711s infinite; }

@keyframes drop-65 {
  0% {
    top: 80px;
    left: 59%; } }
  .SwapCompleted .confetti-66 {
    width: 7px;
    height: 2.8px;
    background-color: #9D4DFA;
    top: 690px;
    left: 87%;
    opacity: 0.61039;
    transform: rotate(146.4263deg);
    animation: drop-66 4.91303s 0.33862s infinite; }

@keyframes drop-66 {
  0% {
    top: 80px;
    left: 88%; } }
  .SwapCompleted .confetti-67 {
    width: 5px;
    height: 2px;
    background-color: #9D4DFA;
    top: 690px;
    left: 77%;
    opacity: 1.01573;
    transform: rotate(183.3553deg);
    animation: drop-67 4.59947s 0.9768s infinite; }

@keyframes drop-67 {
  0% {
    top: 80px;
    left: 82%; } }
  .SwapCompleted .confetti-68 {
    width: 8px;
    height: 3.2px;
    background-color: #1CE5C3;
    top: 690px;
    left: 17%;
    opacity: 0.47599;
    transform: rotate(121.056deg);
    animation: drop-68 4.49731s 0.80479s infinite; }

@keyframes drop-68 {
  0% {
    top: 80px;
    left: 19%; } }
  .SwapCompleted .confetti-69 {
    width: 1px;
    height: 0.4px;
    background-color: #1CE5C3;
    top: 690px;
    left: 84%;
    opacity: 1.2515;
    transform: rotate(208.22001deg);
    animation: drop-69 4.8294s 0.07663s infinite; }

@keyframes drop-69 {
  0% {
    top: 80px;
    left: 85%; } }
  .SwapCompleted .confetti-70 {
    width: 1px;
    height: 0.4px;
    background-color: #9D4DFA;
    top: 690px;
    left: 23%;
    opacity: 1.14982;
    transform: rotate(112.02961deg);
    animation: drop-70 4.53376s 0.78416s infinite; }

@keyframes drop-70 {
  0% {
    top: 80px;
    left: 28%; } }
  .SwapCompleted .confetti-71 {
    width: 5px;
    height: 2px;
    background-color: #F12274;
    top: 690px;
    left: 67%;
    opacity: 0.8743;
    transform: rotate(52.06494deg);
    animation: drop-71 4.35407s 0.41603s infinite; }

@keyframes drop-71 {
  0% {
    top: 80px;
    left: 69%; } }
  .SwapCompleted .confetti-72 {
    width: 2px;
    height: 0.8px;
    background-color: #1CE5C3;
    top: 690px;
    left: 60%;
    opacity: 0.95496;
    transform: rotate(20.89665deg);
    animation: drop-72 4.19751s 0.93434s infinite; }

@keyframes drop-72 {
  0% {
    top: 80px;
    left: 65%; } }
  .SwapCompleted .confetti-73 {
    width: 5px;
    height: 2px;
    background-color: #1CE5C3;
    top: 690px;
    left: 22%;
    opacity: 1.18166;
    transform: rotate(163.6502deg);
    animation: drop-73 4.47999s 0.97836s infinite; }

@keyframes drop-73 {
  0% {
    top: 80px;
    left: 25%; } }
  .SwapCompleted .confetti-74 {
    width: 1px;
    height: 0.4px;
    background-color: #1CE5C3;
    top: 690px;
    left: 21%;
    opacity: 1.213;
    transform: rotate(20.64612deg);
    animation: drop-74 4.15348s 0.15417s infinite; }

@keyframes drop-74 {
  0% {
    top: 80px;
    left: 26%; } }
  .SwapCompleted .confetti-75 {
    width: 5px;
    height: 2px;
    background-color: #1CE5C3;
    top: 690px;
    left: 50%;
    opacity: 0.8155;
    transform: rotate(0.88146deg);
    animation: drop-75 4.66863s 0.73345s infinite; }

@keyframes drop-75 {
  0% {
    top: 80px;
    left: 52%; } }
  .SwapCompleted .confetti-76 {
    width: 7px;
    height: 2.8px;
    background-color: #9D4DFA;
    top: 690px;
    left: 12%;
    opacity: 0.4645;
    transform: rotate(43.45433deg);
    animation: drop-76 4.06889s 0.88469s infinite; }

@keyframes drop-76 {
  0% {
    top: 80px;
    left: 14%; } }
  .SwapCompleted .confetti-77 {
    width: 7px;
    height: 2.8px;
    background-color: #9D4DFA;
    top: 690px;
    left: 55%;
    opacity: 0.64513;
    transform: rotate(337.89532deg);
    animation: drop-77 4.75138s 0.6669s infinite; }

@keyframes drop-77 {
  0% {
    top: 80px;
    left: 57%; } }
  .SwapCompleted .confetti-78 {
    width: 7px;
    height: 2.8px;
    background-color: #9D4DFA;
    top: 690px;
    left: 54%;
    opacity: 1.18941;
    transform: rotate(46.25043deg);
    animation: drop-78 4.94682s 0.88307s infinite; }

@keyframes drop-78 {
  0% {
    top: 80px;
    left: 56%; } }
  .SwapCompleted .confetti-79 {
    width: 7px;
    height: 2.8px;
    background-color: #1CE5C3;
    top: 690px;
    left: 40%;
    opacity: 1.1571;
    transform: rotate(118.01371deg);
    animation: drop-79 4.48821s 0.83992s infinite; }

@keyframes drop-79 {
  0% {
    top: 80px;
    left: 43%; } }
  .SwapCompleted .confetti-80 {
    width: 8px;
    height: 3.2px;
    background-color: #1CE5C3;
    top: 690px;
    left: 12%;
    opacity: 0.82179;
    transform: rotate(188.83338deg);
    animation: drop-80 4.41683s 0.62561s infinite; }

@keyframes drop-80 {
  0% {
    top: 80px;
    left: 16%; } }
  .SwapCompleted .confetti-81 {
    width: 5px;
    height: 2px;
    background-color: #1CE5C3;
    top: 690px;
    left: 19%;
    opacity: 0.56877;
    transform: rotate(19.98266deg);
    animation: drop-81 4.24778s 0.37223s infinite; }

@keyframes drop-81 {
  0% {
    top: 80px;
    left: 20%; } }
  .SwapCompleted .confetti-82 {
    width: 1px;
    height: 0.4px;
    background-color: #9D4DFA;
    top: 690px;
    left: 11%;
    opacity: 1.23975;
    transform: rotate(42.04602deg);
    animation: drop-82 4.55324s 0.22398s infinite; }

@keyframes drop-82 {
  0% {
    top: 80px;
    left: 13%; } }
  .SwapCompleted .confetti-83 {
    width: 8px;
    height: 3.2px;
    background-color: #F12274;
    top: 690px;
    left: 49%;
    opacity: 0.67075;
    transform: rotate(210.49305deg);
    animation: drop-83 4.67919s 0.91521s infinite; }

@keyframes drop-83 {
  0% {
    top: 80px;
    left: 53%; } }
  .SwapCompleted .confetti-84 {
    width: 4px;
    height: 1.6px;
    background-color: #F12274;
    top: 690px;
    left: 84%;
    opacity: 1.11065;
    transform: rotate(259.69334deg);
    animation: drop-84 4.34907s 0.74667s infinite; }

@keyframes drop-84 {
  0% {
    top: 80px;
    left: 87%; } }
  .SwapCompleted .confetti-85 {
    width: 7px;
    height: 2.8px;
    background-color: #F12274;
    top: 690px;
    left: 42%;
    opacity: 0.60498;
    transform: rotate(236.6046deg);
    animation: drop-85 4.52133s 0.36496s infinite; }

@keyframes drop-85 {
  0% {
    top: 80px;
    left: 46%; } }
  .SwapCompleted .confetti-86 {
    width: 7px;
    height: 2.8px;
    background-color: #F12274;
    top: 690px;
    left: 25%;
    opacity: 1.14629;
    transform: rotate(36.01466deg);
    animation: drop-86 4.71053s 0.50376s infinite; }

@keyframes drop-86 {
  0% {
    top: 80px;
    left: 26%; } }
  .SwapCompleted .confetti-87 {
    width: 5px;
    height: 2px;
    background-color: #1CE5C3;
    top: 690px;
    left: 53%;
    opacity: 1.10131;
    transform: rotate(284.57015deg);
    animation: drop-87 4.79534s 0.40207s infinite; }

@keyframes drop-87 {
  0% {
    top: 80px;
    left: 56%; } }
  .SwapCompleted .confetti-88 {
    width: 6px;
    height: 2.4px;
    background-color: #F12274;
    top: 690px;
    left: 7%;
    opacity: 1.29569;
    transform: rotate(76.17921deg);
    animation: drop-88 4.80408s 0.44258s infinite; }

@keyframes drop-88 {
  0% {
    top: 80px;
    left: 8%; } }
  .SwapCompleted .confetti-89 {
    width: 1px;
    height: 0.4px;
    background-color: #F12274;
    top: 690px;
    left: 6%;
    opacity: 1.05457;
    transform: rotate(222.69747deg);
    animation: drop-89 4.58634s 0.3209s infinite; }

@keyframes drop-89 {
  0% {
    top: 80px;
    left: 10%; } }
  .SwapCompleted .confetti-90 {
    width: 3px;
    height: 1.2px;
    background-color: #F12274;
    top: 690px;
    left: 45%;
    opacity: 0.93898;
    transform: rotate(324.93111deg);
    animation: drop-90 4.67479s 0.97195s infinite; }

@keyframes drop-90 {
  0% {
    top: 80px;
    left: 47%; } }
  .SwapCompleted .confetti-91 {
    width: 2px;
    height: 0.8px;
    background-color: #1CE5C3;
    top: 690px;
    left: 48%;
    opacity: 0.70822;
    transform: rotate(282.47931deg);
    animation: drop-91 4.49289s 0.2324s infinite; }

@keyframes drop-91 {
  0% {
    top: 80px;
    left: 52%; } }
  .SwapCompleted .confetti-92 {
    width: 6px;
    height: 2.4px;
    background-color: #1CE5C3;
    top: 690px;
    left: 60%;
    opacity: 0.7611;
    transform: rotate(289.74443deg);
    animation: drop-92 4.02834s 0.89577s infinite; }

@keyframes drop-92 {
  0% {
    top: 80px;
    left: 65%; } }
  .SwapCompleted .confetti-93 {
    width: 8px;
    height: 3.2px;
    background-color: #F12274;
    top: 690px;
    left: 95%;
    opacity: 1.1688;
    transform: rotate(274.87281deg);
    animation: drop-93 4.5102s 0.72077s infinite; }

@keyframes drop-93 {
  0% {
    top: 80px;
    left: 96%; } }
  .SwapCompleted .confetti-94 {
    width: 1px;
    height: 0.4px;
    background-color: #1CE5C3;
    top: 690px;
    left: 90%;
    opacity: 0.70262;
    transform: rotate(40.2079deg);
    animation: drop-94 4.9538s 0.76114s infinite; }

@keyframes drop-94 {
  0% {
    top: 80px;
    left: 94%; } }
  .SwapCompleted .confetti-95 {
    width: 5px;
    height: 2px;
    background-color: #9D4DFA;
    top: 690px;
    left: 41%;
    opacity: 1.27829;
    transform: rotate(230.2419deg);
    animation: drop-95 4.27109s 0.90256s infinite; }

@keyframes drop-95 {
  0% {
    top: 80px;
    left: 43%; } }
  .SwapCompleted .confetti-96 {
    width: 8px;
    height: 3.2px;
    background-color: #9D4DFA;
    top: 690px;
    left: 24%;
    opacity: 0.74833;
    transform: rotate(110.56575deg);
    animation: drop-96 4.81598s 0.90229s infinite; }

@keyframes drop-96 {
  0% {
    top: 80px;
    left: 28%; } }
  .SwapCompleted .confetti-97 {
    width: 4px;
    height: 1.6px;
    background-color: #9D4DFA;
    top: 690px;
    left: 28%;
    opacity: 0.33263;
    transform: rotate(298.44697deg);
    animation: drop-97 4.51746s 0.35271s infinite; }

@keyframes drop-97 {
  0% {
    top: 80px;
    left: 31%; } }
  .SwapCompleted .confetti-98 {
    width: 1px;
    height: 0.4px;
    background-color: #9D4DFA;
    top: 690px;
    left: 10%;
    opacity: 0.44493;
    transform: rotate(268.73857deg);
    animation: drop-98 4.07165s 0.73389s infinite; }

@keyframes drop-98 {
  0% {
    top: 80px;
    left: 13%; } }
  .SwapCompleted .confetti-99 {
    width: 6px;
    height: 2.4px;
    background-color: #F12274;
    top: 690px;
    left: 66%;
    opacity: 1.25341;
    transform: rotate(148.61784deg);
    animation: drop-99 4.55558s 0.46393s infinite; }

@keyframes drop-99 {
  0% {
    top: 80px;
    left: 67%; } }
  .SwapCompleted .confetti-100 {
    width: 8px;
    height: 3.2px;
    background-color: #F12274;
    top: 690px;
    left: 27%;
    opacity: 0.77605;
    transform: rotate(228.06942deg);
    animation: drop-100 4.65125s 0.34455s infinite; }

@keyframes drop-100 {
  0% {
    top: 80px;
    left: 32%; } }
  .SwapCompleted .confetti-101 {
    width: 3px;
    height: 1.2px;
    background-color: #F12274;
    top: 690px;
    left: 94%;
    opacity: 0.50526;
    transform: rotate(156.37273deg);
    animation: drop-101 4.90026s 0.85847s infinite; }

@keyframes drop-101 {
  0% {
    top: 80px;
    left: 99%; } }
  .SwapCompleted .confetti-102 {
    width: 3px;
    height: 1.2px;
    background-color: #9D4DFA;
    top: 690px;
    left: 25%;
    opacity: 0.85417;
    transform: rotate(330.77398deg);
    animation: drop-102 4.18026s 0.9389s infinite; }

@keyframes drop-102 {
  0% {
    top: 80px;
    left: 28%; } }
  .SwapCompleted .confetti-103 {
    width: 1px;
    height: 0.4px;
    background-color: #1CE5C3;
    top: 690px;
    left: 90%;
    opacity: 0.53603;
    transform: rotate(58.87563deg);
    animation: drop-103 4.98456s 0.50978s infinite; }

@keyframes drop-103 {
  0% {
    top: 80px;
    left: 95%; } }
  .SwapCompleted .confetti-104 {
    width: 2px;
    height: 0.8px;
    background-color: #9D4DFA;
    top: 690px;
    left: 91%;
    opacity: 0.88708;
    transform: rotate(349.15285deg);
    animation: drop-104 4.10162s 0.02108s infinite; }

@keyframes drop-104 {
  0% {
    top: 80px;
    left: 93%; } }
  .SwapCompleted .confetti-105 {
    width: 3px;
    height: 1.2px;
    background-color: #9D4DFA;
    top: 690px;
    left: 81%;
    opacity: 0.95977;
    transform: rotate(110.87377deg);
    animation: drop-105 4.96854s 0.2132s infinite; }

@keyframes drop-105 {
  0% {
    top: 80px;
    left: 85%; } }
  .SwapCompleted .confetti-106 {
    width: 6px;
    height: 2.4px;
    background-color: #F12274;
    top: 690px;
    left: 80%;
    opacity: 0.67679;
    transform: rotate(176.53872deg);
    animation: drop-106 4.63675s 0.64689s infinite; }

@keyframes drop-106 {
  0% {
    top: 80px;
    left: 81%; } }
  .SwapCompleted .confetti-107 {
    width: 8px;
    height: 3.2px;
    background-color: #1CE5C3;
    top: 690px;
    left: 80%;
    opacity: 0.92143;
    transform: rotate(37.53183deg);
    animation: drop-107 4.96998s 0.21249s infinite; }

@keyframes drop-107 {
  0% {
    top: 80px;
    left: 82%; } }
  .SwapCompleted .confetti-108 {
    width: 5px;
    height: 2px;
    background-color: #F12274;
    top: 690px;
    left: 30%;
    opacity: 0.44588;
    transform: rotate(15.96728deg);
    animation: drop-108 4.75314s 0.14853s infinite; }

@keyframes drop-108 {
  0% {
    top: 80px;
    left: 34%; } }
  .SwapCompleted .confetti-109 {
    width: 6px;
    height: 2.4px;
    background-color: #1CE5C3;
    top: 690px;
    left: 71%;
    opacity: 0.73993;
    transform: rotate(115.20268deg);
    animation: drop-109 4.51009s 0.14046s infinite; }

@keyframes drop-109 {
  0% {
    top: 80px;
    left: 74%; } }
  .SwapCompleted .confetti-110 {
    width: 6px;
    height: 2.4px;
    background-color: #F12274;
    top: 690px;
    left: 55%;
    opacity: 1.13663;
    transform: rotate(72.24992deg);
    animation: drop-110 4.94387s 0.89884s infinite; }

@keyframes drop-110 {
  0% {
    top: 80px;
    left: 60%; } }
  .SwapCompleted .confetti-111 {
    width: 7px;
    height: 2.8px;
    background-color: #1CE5C3;
    top: 690px;
    left: 60%;
    opacity: 0.78352;
    transform: rotate(288.08975deg);
    animation: drop-111 4.69875s 0.24875s infinite; }

@keyframes drop-111 {
  0% {
    top: 80px;
    left: 61%; } }
  .SwapCompleted .confetti-112 {
    width: 1px;
    height: 0.4px;
    background-color: #9D4DFA;
    top: 690px;
    left: 33%;
    opacity: 0.4596;
    transform: rotate(353.73151deg);
    animation: drop-112 4.24882s 0.58845s infinite; }

@keyframes drop-112 {
  0% {
    top: 80px;
    left: 34%; } }
  .SwapCompleted .confetti-113 {
    width: 1px;
    height: 0.4px;
    background-color: #F12274;
    top: 690px;
    left: 59%;
    opacity: 0.87323;
    transform: rotate(102.66855deg);
    animation: drop-113 4.74546s 0.88922s infinite; }

@keyframes drop-113 {
  0% {
    top: 80px;
    left: 63%; } }
  .SwapCompleted .confetti-114 {
    width: 4px;
    height: 1.6px;
    background-color: #9D4DFA;
    top: 690px;
    left: 73%;
    opacity: 0.9232;
    transform: rotate(286.97456deg);
    animation: drop-114 4.70263s 0.14458s infinite; }

@keyframes drop-114 {
  0% {
    top: 80px;
    left: 76%; } }
  .SwapCompleted .confetti-115 {
    width: 3px;
    height: 1.2px;
    background-color: #1CE5C3;
    top: 690px;
    left: 37%;
    opacity: 0.77136;
    transform: rotate(64.58482deg);
    animation: drop-115 4.24654s 0.67016s infinite; }

@keyframes drop-115 {
  0% {
    top: 80px;
    left: 39%; } }
  .SwapCompleted .confetti-116 {
    width: 8px;
    height: 3.2px;
    background-color: #9D4DFA;
    top: 690px;
    left: 13%;
    opacity: 0.5755;
    transform: rotate(218.91076deg);
    animation: drop-116 4.37557s 0.97086s infinite; }

@keyframes drop-116 {
  0% {
    top: 80px;
    left: 15%; } }
  .SwapCompleted .confetti-117 {
    width: 2px;
    height: 0.8px;
    background-color: #9D4DFA;
    top: 690px;
    left: 15%;
    opacity: 0.82793;
    transform: rotate(138.90244deg);
    animation: drop-117 4.16645s 0.98889s infinite; }

@keyframes drop-117 {
  0% {
    top: 80px;
    left: 19%; } }
  .SwapCompleted .confetti-118 {
    width: 2px;
    height: 0.8px;
    background-color: #F12274;
    top: 690px;
    left: 51%;
    opacity: 0.43929;
    transform: rotate(140.91813deg);
    animation: drop-118 4.57113s 0.35255s infinite; }

@keyframes drop-118 {
  0% {
    top: 80px;
    left: 53%; } }
  .SwapCompleted .confetti-119 {
    width: 7px;
    height: 2.8px;
    background-color: #F12274;
    top: 690px;
    left: 23%;
    opacity: 0.79382;
    transform: rotate(133.31929deg);
    animation: drop-119 4.06759s 0.42974s infinite; }

@keyframes drop-119 {
  0% {
    top: 80px;
    left: 25%; } }
  .SwapCompleted .confetti-120 {
    width: 6px;
    height: 2.4px;
    background-color: #1CE5C3;
    top: 690px;
    left: 15%;
    opacity: 0.43218;
    transform: rotate(33.10782deg);
    animation: drop-120 4.11195s 0.81239s infinite; }

@keyframes drop-120 {
  0% {
    top: 80px;
    left: 17%; } }
  .SwapCompleted .confetti-121 {
    width: 2px;
    height: 0.8px;
    background-color: #1CE5C3;
    top: 690px;
    left: 32%;
    opacity: 0.91384;
    transform: rotate(118.92104deg);
    animation: drop-121 4.8096s 0.31237s infinite; }

@keyframes drop-121 {
  0% {
    top: 80px;
    left: 35%; } }
  .SwapCompleted .confetti-122 {
    width: 2px;
    height: 0.8px;
    background-color: #F12274;
    top: 690px;
    left: 3%;
    opacity: 0.54952;
    transform: rotate(122.21029deg);
    animation: drop-122 4.02023s 0.91876s infinite; }

@keyframes drop-122 {
  0% {
    top: 80px;
    left: 6%; } }
  .SwapCompleted .confetti-123 {
    width: 3px;
    height: 1.2px;
    background-color: #1CE5C3;
    top: 690px;
    left: 41%;
    opacity: 0.74561;
    transform: rotate(282.30849deg);
    animation: drop-123 4.45814s 0.53245s infinite; }

@keyframes drop-123 {
  0% {
    top: 80px;
    left: 45%; } }
  .SwapCompleted .confetti-124 {
    width: 3px;
    height: 1.2px;
    background-color: #1CE5C3;
    top: 690px;
    left: 16%;
    opacity: 0.6284;
    transform: rotate(158.14259deg);
    animation: drop-124 4.01729s 0.163s infinite; }

@keyframes drop-124 {
  0% {
    top: 80px;
    left: 19%; } }
  .SwapCompleted .confetti-125 {
    width: 8px;
    height: 3.2px;
    background-color: #9D4DFA;
    top: 690px;
    left: 12%;
    opacity: 0.60032;
    transform: rotate(150.85186deg);
    animation: drop-125 4.8754s 0.91224s infinite; }

@keyframes drop-125 {
  0% {
    top: 80px;
    left: 14%; } }
  .SwapCompleted .confetti-126 {
    width: 3px;
    height: 1.2px;
    background-color: #1CE5C3;
    top: 690px;
    left: 58%;
    opacity: 0.57147;
    transform: rotate(82.55468deg);
    animation: drop-126 4.99732s 0.0999s infinite; }

@keyframes drop-126 {
  0% {
    top: 80px;
    left: 62%; } }
  .SwapCompleted .confetti-127 {
    width: 4px;
    height: 1.6px;
    background-color: #9D4DFA;
    top: 690px;
    left: 18%;
    opacity: 1.04184;
    transform: rotate(309.14452deg);
    animation: drop-127 4.84288s 0.21054s infinite; }

@keyframes drop-127 {
  0% {
    top: 80px;
    left: 21%; } }
  .SwapCompleted .confetti-128 {
    width: 3px;
    height: 1.2px;
    background-color: #9D4DFA;
    top: 690px;
    left: 79%;
    opacity: 1.05523;
    transform: rotate(270.24792deg);
    animation: drop-128 4.77448s 0.831s infinite; }

@keyframes drop-128 {
  0% {
    top: 80px;
    left: 82%; } }
  .SwapCompleted .confetti-129 {
    width: 1px;
    height: 0.4px;
    background-color: #9D4DFA;
    top: 690px;
    left: 31%;
    opacity: 0.62685;
    transform: rotate(229.54737deg);
    animation: drop-129 4.80651s 0.36442s infinite; }

@keyframes drop-129 {
  0% {
    top: 80px;
    left: 34%; } }
  .SwapCompleted .confetti-130 {
    width: 2px;
    height: 0.8px;
    background-color: #F12274;
    top: 690px;
    left: 69%;
    opacity: 1.04968;
    transform: rotate(251.11979deg);
    animation: drop-130 4.22181s 0.50517s infinite; }

@keyframes drop-130 {
  0% {
    top: 80px;
    left: 74%; } }
  .SwapCompleted .confetti-131 {
    width: 2px;
    height: 0.8px;
    background-color: #9D4DFA;
    top: 690px;
    left: 93%;
    opacity: 0.94039;
    transform: rotate(302.50326deg);
    animation: drop-131 4.25532s 0.94825s infinite; }

@keyframes drop-131 {
  0% {
    top: 80px;
    left: 94%; } }
  .SwapCompleted .confetti-132 {
    width: 4px;
    height: 1.6px;
    background-color: #1CE5C3;
    top: 690px;
    left: 19%;
    opacity: 1.22546;
    transform: rotate(108.11809deg);
    animation: drop-132 4.00402s 0.96072s infinite; }

@keyframes drop-132 {
  0% {
    top: 80px;
    left: 21%; } }
  .SwapCompleted .confetti-133 {
    width: 7px;
    height: 2.8px;
    background-color: #9D4DFA;
    top: 690px;
    left: 92%;
    opacity: 0.8305;
    transform: rotate(180.7279deg);
    animation: drop-133 4.80231s 0.951s infinite; }

@keyframes drop-133 {
  0% {
    top: 80px;
    left: 97%; } }
  .SwapCompleted .confetti-134 {
    width: 6px;
    height: 2.4px;
    background-color: #9D4DFA;
    top: 690px;
    left: 51%;
    opacity: 0.53396;
    transform: rotate(316.79776deg);
    animation: drop-134 4.72557s 0.92515s infinite; }

@keyframes drop-134 {
  0% {
    top: 80px;
    left: 55%; } }
  .SwapCompleted .confetti-135 {
    width: 3px;
    height: 1.2px;
    background-color: #1CE5C3;
    top: 690px;
    left: 62%;
    opacity: 0.77062;
    transform: rotate(177.82938deg);
    animation: drop-135 4.32027s 0.80338s infinite; }

@keyframes drop-135 {
  0% {
    top: 80px;
    left: 67%; } }
  .SwapCompleted .confetti-136 {
    width: 2px;
    height: 0.8px;
    background-color: #1CE5C3;
    top: 690px;
    left: 26%;
    opacity: 0.6287;
    transform: rotate(183.22363deg);
    animation: drop-136 4.43386s 0.48426s infinite; }

@keyframes drop-136 {
  0% {
    top: 80px;
    left: 30%; } }
  .SwapCompleted .confetti-137 {
    width: 8px;
    height: 3.2px;
    background-color: #F12274;
    top: 690px;
    left: 87%;
    opacity: 0.52214;
    transform: rotate(160.60133deg);
    animation: drop-137 4.71318s 0.93608s infinite; }

@keyframes drop-137 {
  0% {
    top: 80px;
    left: 92%; } }
  .SwapCompleted .confetti-138 {
    width: 2px;
    height: 0.8px;
    background-color: #9D4DFA;
    top: 690px;
    left: 73%;
    opacity: 0.72255;
    transform: rotate(7.73089deg);
    animation: drop-138 4.15836s 0.96999s infinite; }

@keyframes drop-138 {
  0% {
    top: 80px;
    left: 78%; } }
  .SwapCompleted .confetti-139 {
    width: 1px;
    height: 0.4px;
    background-color: #1CE5C3;
    top: 690px;
    left: 46%;
    opacity: 0.5947;
    transform: rotate(174.38176deg);
    animation: drop-139 4.93783s 0.36133s infinite; }

@keyframes drop-139 {
  0% {
    top: 80px;
    left: 51%; } }
  .SwapCompleted .confetti-140 {
    width: 8px;
    height: 3.2px;
    background-color: #F12274;
    top: 690px;
    left: 82%;
    opacity: 0.85254;
    transform: rotate(269.76366deg);
    animation: drop-140 4.44657s 0.84721s infinite; }

@keyframes drop-140 {
  0% {
    top: 80px;
    left: 85%; } }
  .SwapCompleted .confetti-141 {
    width: 8px;
    height: 3.2px;
    background-color: #9D4DFA;
    top: 690px;
    left: 48%;
    opacity: 0.86378;
    transform: rotate(222.84872deg);
    animation: drop-141 4.45789s 0.564s infinite; }

@keyframes drop-141 {
  0% {
    top: 80px;
    left: 51%; } }
  .SwapCompleted .confetti-142 {
    width: 1px;
    height: 0.4px;
    background-color: #9D4DFA;
    top: 690px;
    left: 94%;
    opacity: 1.11495;
    transform: rotate(30.00335deg);
    animation: drop-142 4.36633s 0.18752s infinite; }

@keyframes drop-142 {
  0% {
    top: 80px;
    left: 99%; } }
  .SwapCompleted .confetti-143 {
    width: 4px;
    height: 1.6px;
    background-color: #9D4DFA;
    top: 690px;
    left: 6%;
    opacity: 1.08607;
    transform: rotate(294.81235deg);
    animation: drop-143 4.5748s 0.4621s infinite; }

@keyframes drop-143 {
  0% {
    top: 80px;
    left: 8%; } }
  .SwapCompleted .confetti-144 {
    width: 2px;
    height: 0.8px;
    background-color: #F12274;
    top: 690px;
    left: 25%;
    opacity: 0.98659;
    transform: rotate(23.89279deg);
    animation: drop-144 4.48399s 0.37406s infinite; }

@keyframes drop-144 {
  0% {
    top: 80px;
    left: 28%; } }
  .SwapCompleted .confetti-145 {
    width: 3px;
    height: 1.2px;
    background-color: #9D4DFA;
    top: 690px;
    left: 48%;
    opacity: 0.38989;
    transform: rotate(40.97483deg);
    animation: drop-145 4.37209s 0.95669s infinite; }

@keyframes drop-145 {
  0% {
    top: 80px;
    left: 53%; } }
  .SwapCompleted .confetti-146 {
    width: 6px;
    height: 2.4px;
    background-color: #9D4DFA;
    top: 690px;
    left: 4%;
    opacity: 1.244;
    transform: rotate(195.44406deg);
    animation: drop-146 4.60564s 0.2953s infinite; }

@keyframes drop-146 {
  0% {
    top: 80px;
    left: 5%; } }
  .SwapCompleted .confetti-147 {
    width: 2px;
    height: 0.8px;
    background-color: #9D4DFA;
    top: 690px;
    left: 35%;
    opacity: 0.36658;
    transform: rotate(19.28273deg);
    animation: drop-147 4.95085s 0.30909s infinite; }

@keyframes drop-147 {
  0% {
    top: 80px;
    left: 38%; } }
  .SwapCompleted .confetti-148 {
    width: 7px;
    height: 2.8px;
    background-color: #F12274;
    top: 690px;
    left: 11%;
    opacity: 0.46398;
    transform: rotate(83.23672deg);
    animation: drop-148 4.93037s 0.0595s infinite; }

@keyframes drop-148 {
  0% {
    top: 80px;
    left: 12%; } }
  .SwapCompleted .confetti-149 {
    width: 3px;
    height: 1.2px;
    background-color: #1CE5C3;
    top: 690px;
    left: 36%;
    opacity: 1.23281;
    transform: rotate(19.97916deg);
    animation: drop-149 4.92592s 0.4409s infinite; }

@keyframes drop-149 {
  0% {
    top: 80px;
    left: 37%; } }
  .SwapCompleted .confetti-150 {
    width: 8px;
    height: 3.2px;
    background-color: #9D4DFA;
    top: 690px;
    left: 24%;
    opacity: 1.19765;
    transform: rotate(269.10501deg);
    animation: drop-150 4.73122s 0.21162s infinite; }

@keyframes drop-150 {
  0% {
    top: 80px;
    left: 25%; } }
